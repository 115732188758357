import React from 'react';
import Hero from './Hero';
import Footer from './Footer';
import "tailwindcss/tailwind.css";

export default function Layout({children}) {
    return (
        <div>
            <Hero />
            {children}
            <Footer />
        </div>
    )
}
